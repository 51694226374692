"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250328161140/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var styles = require("./TooltipContainer.module.scss");

var HoverSurface_1 = require("./HoverSurface");

exports.TooltipContainer = function (props) {
  var surfaceProps = _objectSpread({
    place: 'top'
  }, props);

  var contentStyle = props.contentStyle || {};
  var contentClassName = props.contentClassName || '';
  var tooltipStyle = props.tooltipLook || 'dark';
  var infoWarning = props.infoWarning;
  var classNames = [styles["tooltip-".concat(tooltipStyle)], contentClassName];

  if (surfaceProps.place === 'right') {
    classNames.push(styles['place-right']);
  }

  return React.createElement(HoverSurface_1.HoverSurface, Object.assign({}, surfaceProps, {
    surfaceContent: props.children,
    wrapperStyle: props.wrapperStyle,
    infoWarning: infoWarning
  }), React.createElement("div", {
    className: classNames.join(' '),
    style: contentStyle
  }, props.tooltipContent));
};
"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250328161140/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$USER_GROUP_T;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.USER_GROUP_TRANSLATIONS = (_exports$USER_GROUP_T = {}, _defineProperty(_exports$USER_GROUP_T, types_1.USER_GROUPS.RESELLER_SALES_CONTACT, 'Contact revendeur'), _defineProperty(_exports$USER_GROUP_T, types_1.USER_GROUPS.DTP_OPERATOR, 'Opérateur PAO'), _exports$USER_GROUP_T);
import './TechnicalInformation.scss';
import * as React from 'react';
import { CENTRAL_STATION_HOST } from '@oyp/prod-center/src/env-config';
import { CalculatorMaterial, CalculatorRequestData } from '@oyp/shared-lib';
import { Card, formatter } from '@oyp/shared-components';
import StyledLink from '../../../components/StyledLink';

const { formatSquareMeter, formatWeight } = formatter;

interface TechnicalInformationProps {
  requestData: CalculatorRequestData;
  material: CalculatorMaterial;
  technicalFileLink: string;
}

const TechnicalInformation: React.FC<TechnicalInformationProps> = props => {
  const { requestData, material, technicalFileLink } = props;

  const quantity = requestData.quantity;
  const surface = requestData.dimensions.height * requestData.dimensions.width;
  const unitWeight = material ? (material.weight * surface) / 10000 : 0;

  return (
    <div>
      <Card
        title="DONNEES TECHNIQUES"
        internalTopPadding
        className="bg-primary-lighter-card-form technical-informations"
      >
        <div>
          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Surface unitaire</div>
            <div className="col-sm-5 text-right">
              {surface > 0 ? formatSquareMeter(surface) : '-'}
            </div>
          </div>

          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Poids unitaire</div>
            <div className="col-sm-5 text-right">
              {surface > 0 ? formatWeight(unitWeight) : '-'}
            </div>
          </div>

          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Surface totale</div>
            <div className="col-sm-5 text-right">
              {surface > 0 ? formatSquareMeter(surface * quantity) : '-'}
            </div>
          </div>

          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Poids total</div>
            <div className="col-sm-5 text-right">
              {surface > 0 ? formatWeight(unitWeight * quantity) : '-'}
            </div>
          </div>

          <div className="row pl-3 pr-3">
            <div className="col-sm-12">
              <StyledLink
                as="a"
                href={technicalFileLink}
                target="_blank"
                rel="noopener noreferrer"
                to=""
              >
                Voir la fiche technique
              </StyledLink>
            </div>
          </div>

          {material.type === 'magnetic' && (
            <div className="row pl-3 pr-3">
              <div className="col-sm-12">
                <StyledLink
                  as="a"
                  href={`${CENTRAL_STATION_HOST}/documents/instructions_pour_magnetique_vehicule.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  to=""
                >
                  Voir les instructions d&apos;utilisation
                </StyledLink>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default TechnicalInformation;

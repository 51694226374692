"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250328161140/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$PASSED_FOR_P;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.PASSED_FOR_PRINT_STATUS_TRANSLATIONS = (_exports$PASSED_FOR_P = {}, _defineProperty(_exports$PASSED_FOR_P, types_1.PASSED_FOR_PRINT_STATUSES.TO_MAKE, 'A faire'), _defineProperty(_exports$PASSED_FOR_P, types_1.PASSED_FOR_PRINT_STATUSES.SENT, 'Envoyé'), _defineProperty(_exports$PASSED_FOR_P, types_1.PASSED_FOR_PRINT_STATUSES.REFUSED, 'Refusé'), _defineProperty(_exports$PASSED_FOR_P, types_1.PASSED_FOR_PRINT_STATUSES.ACCEPTED, 'Accepté'), _exports$PASSED_FOR_P);